import Modal from '@mui/material/Modal';
import { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { Typography } from '@vibTheme/components/_base/Typography';
import CloseIcon from '@mui/icons-material/Close';

export type VibModalProps = PropsWithChildren & {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
};

export const VibModal = (props: VibModalProps) => {
  const { isOpen, setIsOpen, title, children } = props;

  const handleClose = () => setIsOpen((isOpen) => !isOpen);

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="flex items-center justify-center p-main"
    >
      <div className="bg-white p-main rounded-default shadow-default max-w-full max-h-full overflow-y-auto">
        <div className={`flex justify-between mb-main ${title && 'divider'}`}>
          <Typography component="h1">{title}</Typography>
          <CloseIcon
            className="text-primary ml-main hover:cursor-pointer"
            onClick={handleClose}
          />
        </div>

        {children}
      </div>
    </Modal>
  );
};
