import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonProps } from '@mui/material';
import { twMerge } from 'tailwind-merge';

type Props = ButtonProps & {
  loading?: boolean;
};

export const Button = (props: Props) => {
  const buttonStyles = 'rounded-full [&_.MuiButton-startIcon]:mr-1';

  return (
    <LoadingButton
      variant="contained"
      size="large"
      {...props}
      className={twMerge(buttonStyles, props.className)}
    >
      {props.children}
    </LoadingButton>
  );
};
