import { signOut } from 'next-auth/react';

export type FetcherOptions = Omit<RequestInit, 'body'> & {
  body?: object;
  throwOnError?: boolean;
};

export async function fetcher<T>(
  url: string,
  options: FetcherOptions = {},
): Promise<T> {
  const { throwOnError = true, ...restOptions } = options;

  const corsCustomHeaderName =
    process.env.NEXT_PUBLIC_CUSTOM_CORS_HEADER_NAME ?? '';
  const corsCustomHeaderValue =
    process.env.NEXT_PUBLIC_CUSTOM_CORS_HEADER_VALUE ?? '';

  const parsedBody = JSON.stringify(restOptions.body);
  const parsedOptions = {
    ...restOptions,
    body: parsedBody,
    headers: {
      ...restOptions.headers,
      [corsCustomHeaderName]: corsCustomHeaderValue,
    },
  };

  const response = await (await fetch(url, parsedOptions)).json();

  const status = response.status || response.statusCode;
  if (throwOnError && status >= 400) {
    // O retorno de objeto vazio tem o objetivo de não lançar erro e
    // não exibir um toast caso o redirecionamento para a página de login seja necessário
    if (status === 401) {
      signOut();
      return {} as T;
    }

    throw response;
  }

  return response as T;
}
